import React from 'react';

import { StyledFooterContainer } from '../styled';
import { PRIVACY_URL } from 'shared/modules/auth-module/constants';
import { Body } from 'tuxedo-v2';

function FooterComponent(): JSX.Element {
  return (
    <StyledFooterContainer>
      <a
        href={PRIVACY_URL}
        target={'_blank'}
        style={{ textDecoration: 'none' }}
      >
        <Body size='small' weight='regular' color='text2'>
          Privacy Policy
        </Body>
      </a>
    </StyledFooterContainer>
  );
}

export default FooterComponent;
