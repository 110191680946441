export const CODE_VERIFIER_LENGTH = 48;
export const FPJS_API_KEY = '89GH82zODFbWS0ekGNno';

export const PRIVACY_URL = 'https://synaptic.com/privacy-policy.html';
export const IDP_LIST = [
  {
    name: 'Okta',
    provider: 'okta',
    slug: 'okta',
    logo: 'https://859747976171-staging-labs-core.s3.amazonaws.com/assets/Okta_logo+1.png'
  },
  {
    name: 'Microsoft',
    provider: 'azure',
    slug: 'azure',
    logo: 'https://859747976171-staging-labs-core.s3.amazonaws.com/assets/microsoft-auth-logo.png'
  },
  {
    name: 'Google',
    provider: 'google',
    slug: 'google',
    logo: 'https://859747976171-staging-labs-core.s3.amazonaws.com/assets/Google+(1).png'
  },
  {
    name: 'SAML SSO',
    provider: 'saml',
    slug: 'saml_sso',
    logo: 'https://859747976171-staging-labs-core.s3.amazonaws.com/assets/saml.png'
  }
];

export {
  FIREBASE_WEB_CLIENT_ID,
  CLIENT_ID,
  FPJS_ENDPOINT
} from 'shared/modules/auth-module/constants/platform-config';
